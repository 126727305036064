import { render, staticRenderFns } from "./Employeenew.vue?vue&type=template&id=46ed2cdd&scoped=true&lang=pug&"
import script from "./Employeenew.vue?vue&type=script&lang=ts&"
export * from "./Employeenew.vue?vue&type=script&lang=ts&"
import style0 from "./Employeenew.vue?vue&type=style&index=0&id=46ed2cdd&prod&lang=scss&"
import style1 from "./Employeenew.vue?vue&type=style&index=1&id=46ed2cdd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ed2cdd",
  null
  
)

export default component.exports